import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigationOpen: (state) => {
      state.open = true;
    },
    setNavigationClosed: (state) => {
      state.open = false;
    },
  },
});

export const { setNavigationOpen, setNavigationClosed } = navigationSlice.actions;

export const selectNavigationOpen = (state) => state.navigation.open;

export default navigationSlice.reducer;
