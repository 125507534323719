import React from 'react';
import Sidebar from './navigation/Sidebar';
import Box from '@mui/material/Box';

const DefaultLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1 }} p={2}>
        {children}
      </Box>
    </Box>
  );
};

export default DefaultLayout;
