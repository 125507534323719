import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MuiIconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faHouse, faGear, faGamepad } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectNavigationOpen, setNavigationClosed, setNavigationOpen } from './navigationSlice';
import ThemeToggle from '../theme/ThemeToggle';

export const SIDEBAR_WIDTH = 240;

const openedMixin = (theme) => ({
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDEBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  margin: theme.spacing(1),
  minHeight: theme.spacing(6),
  borderRadius: theme.spacing(6),
  padding: theme.spacing(1, 2.5),
  // px: 2.5,
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  // margin: theme.spacing(1),
  // minHeight: theme.spacing(6),
  // borderRadius: theme.spacing(6),
  // padding: theme.spacing(1, 2.5),
  // px: 2.5,
}));

const nav = [
  {
    name: 'Home',
    path: '/',
    icon: <FontAwesomeIcon icon={faHouse} size="lg" />
  },
  // {
  //   name: 'Today',
  //   path: '/today',
  //   icon: <FontAwesomeIcon icon={faBookmark} size="lg" />
  // },
  // {
  //   name: 'Games',
  //   path: '/games',
  //   icon: <FontAwesomeIcon icon={faGamepad} size="lg" />
  // },
  // {
  //   name: 'Settings',
  //   path: '/settings',
  //   icon: <FontAwesomeIcon icon={faGear} size="lg" />
  // },
];

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const open = useSelector(selectNavigationOpen);
  const [ gamesOpen, setGamesOpen ] = useState(false);

  const handleGamesToggle = () => {
    setGamesOpen(!gamesOpen);
  };

  const [ selected, setSelected ] = useState('/');

  const handleDrawerToggle = () => {
    if (open) {
      dispatch(setNavigationClosed());
    }
    else {
      dispatch(setNavigationOpen());
    }
  };

  useEffect(() => {
    setSelected(pathname);
  }, [ pathname ]);

  const handleNav = (route) => navigate(route);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {nav.map(({ name, path, icon }) => (
          <ListItem key={name} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                justifyContent: open ? 'initial' : 'center',
              }}
              selected={selected === path}
              onClick={() => handleNav(path)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={name} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Divider  sx={{ flexGrow: 1 }} />
      <ThemeToggle />
    </Drawer>
  );
};

export default Sidebar;
