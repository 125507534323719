import { createBrowserRouter, Outlet } from 'react-router-dom';
import DefaultLayout from './layouts/DefaultLayout';
// import Dashboard from './pages/Dashboard';
// import Games from './pages/games/Games';
// import Today from './pages/Today';
// import Settings from './pages/Settings';
import ComingSoon from './pages/ComingSoon';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <DefaultLayout>
        <Outlet />
      </DefaultLayout>
    ),
    // errorElement: <div>ERROR</div>,
    children: [
      {
        index: true,
        element: <ComingSoon />,
        // element: <Dashboard />,
      },
      // {
      //   path: 'today',
      //   element: <Today />
      // },
      // {
      //   path: 'games',
      //   element: <Games />
      // },
      // {
      //   path: 'settings',
      //   element: <Settings />
      // },
    ],
  },
]);
