import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './BaseThemes';
import { router } from './routes';

const App = () => {
  return (
    <CssVarsProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </CssVarsProvider>
  );
};

export default App;
