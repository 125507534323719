/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://fqbamuevkbd7nhixrpvat4jqla.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:7f684e3a-d1df-43af-8cd2-76a5c60f441c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_zMa6bCkTr",
    "aws_user_pools_web_client_id": "3akh0p99ecv7l5rf4b7cfghm28",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "completionistf865495341b34568950753bf0f79029a193704-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
