import React from 'react';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';

const ComingSoon = () => {
  return (
    <Container maxWidth="sm">
      <Alert severity="warning" variant="outlined">Coming Soon...?</Alert>
    </Container>
  );
};

export default ComingSoon;
