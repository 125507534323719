import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepPurple, pink } from '@mui/material/colors';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const base = {
  palette: {
    primary: {
      main: deepPurple[500],
      light: '#9a67ea',
      dark: '#320b86',
    },
    secondary: {
      main: pink[500],
      light: '#ff6090',
      dark: '#b0003a',
    },
  },
};

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#6750A4',
        },
        secondary: {
          main: '#958DA5',
        },
        tertiary: {
          main: '#B58392',
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: '#6750A4',
        },
        secondary: {
          main: '#958DA5',
        },
        tertiary: {
          main: '#B58392',
        },
      },
    }
  }
});
